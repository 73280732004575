import NextLink from 'next/link';

type LinkProps = {
  children: React.ReactNode;
  href: string;
  locale: string;
  nofollow?: boolean;
};

type ExternalLinkProps = {
  children: React.ReactNode;
  href: string;
  nofollow?: boolean;
};

const InternalLink = ({ children, href, locale, nofollow }: LinkProps) => {
  return (
    <NextLink
      href={href}
      locale={locale}
      rel={nofollow ? 'nofollow' : ''}
      className="hover:underline hover:underline-offset-4 focus:outline-offset-4 focus:outline-lilac-1"
    >
      {children}
    </NextLink>
  );
};

const ExternalLink = ({ children, href, nofollow }: ExternalLinkProps) => {
  return (
    <a
      href={href}
      className="hover:underline hover:underline-offset-4 focus:outline-offset-4 focus:outline-lilac-1"
      target="_blank"
      rel={`noreferrer ${nofollow ? 'nofollow' : ''}`}
    >
      {children}
    </a>
  );
};

export const NavigationLink = ({
  children,
  href,
  locale,
  nofollow,
}: LinkProps) => {
  if (!href) {
    return null;
  }
  const isExternalLink = href.startsWith('http');

  return (
    <div>
      {isExternalLink ? (
        <ExternalLink href={href} nofollow={nofollow}>
          {children}
        </ExternalLink>
      ) : (
        <InternalLink href={href} locale={locale} nofollow={nofollow}>
          {children}
        </InternalLink>
      )}
    </div>
  );
};
