import LegalLinks from '@otovo/shared/components/LegalLinks';
import MarketWrapper from '@otovo/shared/components/MarketWrapper';
import { NavigationLink } from '@otovo/shared/components/Navigation/Links';
import m from '@otovo/shared/components/Navigation/messages';
import SocialMediaLinks from '@otovo/shared/components/SocialMediaLinks/SocialMediaLinks';
import { useGlobalConfig } from '@otovo/shared/hooks/configContext';
import { Otovo$Locale } from '@otovo/shared/types/otovoweb';
import { useRouter } from 'next/router';
import { FormattedMessage as T } from 'react-intl';
import OtovoLogoWhite from '../../illustrations/otovo-logo-white.svg';
import MyPageButton from './MyPageButton';

const MobileFooter = () => {
  const config = useGlobalConfig();
  const { locale } = useRouter() as { locale: Otovo$Locale };

  const cancellationFormId =
    config.BU_CONFIG.hubspot_cancellation_form_ids?.[locale];

  const hasCancellationForm =
    !!cancellationFormId && cancellationFormId !== '0';

  return (
    <div className="w-full bg-grey-90 px-5 py-11 text-lilac-20">
      <div className="col-span-full flex items-center justify-between text-grey-90">
        <OtovoLogoWhite className="h-24" />
        <MyPageButton />
      </div>
      <div className="mt-14 space-y-1 text-lg">
        <NavigationLink href="/about/" locale={locale}>
          <T {...m.aboutUs} />
        </NavigationLink>
        <NavigationLink href="/partner/" locale={locale}>
          <T {...m.partnerPageTitle} />
        </NavigationLink>
        {!!config.CAREERS_URL && (
          <NavigationLink locale={locale} href={config.CAREERS_URL}>
            <T {...m.positions} />
          </NavigationLink>
        )}
        {config.urls?.referralPageUrl && (
          <NavigationLink locale={locale} href={config.urls.referralPageUrl}>
            <T {...m.otovoReferralProgram} />
          </NavigationLink>
        )}
        <NavigationLink
          locale={locale}
          href={`${config.INSTALLER_WEB_URL}/signup/${locale}/`}
        >
          <T {...m.becomeAnInstaller} />
        </NavigationLink>
        {!!config.HUBSPOT_KNOWLEDGE_BASE_URL && (
          <NavigationLink
            href={config.HUBSPOT_KNOWLEDGE_BASE_URL}
            locale={locale}
            nofollow
          >
            <T {...m.faqTitle} />
          </NavigationLink>
        )}

        <NavigationLink href="/support/" locale={locale}>
          <T {...m.support} />
        </NavigationLink>
        {!!config.urls?.blogUrl && (
          <NavigationLink href={config.urls.blogUrl} locale={locale}>
            <T {...m.blogTitle} />
          </NavigationLink>
        )}
        {hasCancellationForm && (
          <NavigationLink
            href={`${config.STOREFRONT_URL}/cancellation-form`}
            locale={locale}
          >
            <T {...m.cancellationForm} />
          </NavigationLink>
        )}
      </div>
      <SocialMediaLinks type="light" />
      <LegalLinks />
    </div>
  );
};

const DesktopFooter = () => {
  const config = useGlobalConfig();
  const { locale } = useRouter() as { locale: Otovo$Locale };

  const cancellationFormId =
    config.BU_CONFIG.hubspot_cancellation_form_ids?.[locale];

  const hasCancellationForm =
    !!cancellationFormId && cancellationFormId !== '0';

  return (
    <div className="w-full bg-grey-90 px-5 pb-5 pt-11 text-lilac-20">
      <div className="mx-auto grid max-w-7xl grid-cols-4 gap-4 text-lg">
        <OtovoLogoWhite className="h-24" />
        <div className="col-span-1" />
        <div className="space-y-1">
          <NavigationLink href="/about/" locale={locale}>
            <T {...m.aboutUs} />
          </NavigationLink>
          {!!config.CAREERS_URL && (
            <NavigationLink locale={locale} href={config.CAREERS_URL}>
              <T {...m.positions} />
            </NavigationLink>
          )}
          <NavigationLink href="/partner/" locale={locale}>
            <T {...m.partnerPageTitle} />
          </NavigationLink>
          <NavigationLink
            locale={locale}
            href={`${config.INSTALLER_WEB_URL}/signup/${locale}/`}
          >
            <T {...m.becomeAnInstaller} />
          </NavigationLink>
          {config.urls?.referralPageUrl && (
            <NavigationLink locale={locale} href={config.urls.referralPageUrl}>
              <T {...m.otovoReferralProgram} />
            </NavigationLink>
          )}
        </div>
        <div className="space-y-1">
          {!!config.HUBSPOT_KNOWLEDGE_BASE_URL && (
            <NavigationLink
              href={config.HUBSPOT_KNOWLEDGE_BASE_URL}
              locale={locale}
              nofollow
            >
              <T {...m.faqTitle} />
            </NavigationLink>
          )}
          <NavigationLink href="/support/" locale={locale}>
            <T {...m.support} />
          </NavigationLink>
          {!!config.urls?.blogUrl && (
            <NavigationLink href={config.urls.blogUrl} locale={locale}>
              <T {...m.blogTitle} />
            </NavigationLink>
          )}
          {hasCancellationForm && (
            <NavigationLink
              href={`${config.STOREFRONT_URL}/cancellation-form`}
              locale={locale}
            >
              <T {...m.cancellationForm} />
            </NavigationLink>
          )}
          <MarketWrapper markets={['no']}>
            <NavigationLink
              href={`${config.STOREFRONT_URL}/a/humanrights`}
              locale={locale}
            >
              <T {...m.transparencyAct} />
            </NavigationLink>
          </MarketWrapper>
        </div>
        <div className="col-span-full mt-28" />
        <div className="col-span-2 self-center">
          <LegalLinks />
        </div>
        <div className="self-center">
          <MyPageButton />
        </div>
        <div className="self-center">
          <SocialMediaLinks type="light" />
        </div>
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <>
      <div className="md:hidden">
        <MobileFooter />
      </div>
      <div className="hidden md:block">
        <DesktopFooter />
      </div>
    </>
  );
};

export default Footer;
