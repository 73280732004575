import { FormattedMessage as T } from 'react-intl';
import m from '@otovo/shared/components/Navigation/messages';
import { useGlobalConfig } from '@otovo/shared/hooks/configContext';
import { cn } from '@otovo/shared/lib/classNames';

const MyPageButton = () => {
  const config = useGlobalConfig();
  return (
    <div className="col-span-full flex items-center justify-between text-sm text-grey-90">
      <a
        href={config.MYPAGE_URL}
        className={cn(
          'flex rounded-full bg-lilac-20 px-6 py-2',
          'transition-all hover:-translate-y-1 hover:bg-lilac-1',
          'focus:outline-offset-4 focus:outline-lilac-1',
        )}
      >
        <svg
          fill="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className="w-6"
        >
          <path
            fillRule="evenodd"
            d="M12 4a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM7 7a5 5 0 1 1 10 0A5 5 0 0 1 7 7Zm3 8a4 4 0 0 0-4 4v1a1 1 0 1 1-2 0v-1a6 6 0 0 1 6-6h4a6 6 0 0 1 6 6v1a1 1 0 1 1-2 0v-1a4 4 0 0 0-4-4h-4Z"
            clipRule="evenodd"
          />
        </svg>
        <p className="ml-2 self-center">
          <T {...m.myPage} />
        </p>
      </a>
    </div>
  );
};

export default MyPageButton;
